<template>
  <section>
    <Basket v-if="page_type === 'basket'"/>
    <Order v-if="page_type === 'order'"/>
    <ThankYou v-else-if="page_type === 'thank-you'"/>
    <ContactPage v-if="page_type === 'contact-us'"/>
    <About v-if="page_type === 'about'"/>
    <LegalPage v-if="$route.path.includes('legal-page')" />
  </section>
</template>

<script>
// Data imports
import { mapState } from "vuex"
import {
  COUPLE,
  MULTI
} from "../../../../data/product_variety_types"
import { home } from "../../../../data/other_constants"
import { CLIENT_STORE } from "../../constants/other"

const Basket = () => import("../../../Shared/components/Basket")
const Order = () => import("../../../Shared/components/Order")
const ThankYou = () => import("../default-pages/Thank_you")
const ContactPage = () => import("../default-pages/Contact_page")
const LegalPage = () => import("../default-pages/Legal_page")
const About = () => import("../default-pages/About_page")

export default {
  components: {
    Basket,
    Order,
    ThankYou,
    ContactPage,
    LegalPage,
    About
  },
  data() {
    return {
      COUPLE,
      MULTI,
      home
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "category_of_product_variety"
    ]),
    page_type() {
      return this.$route.params.type
    }
  },
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;

  .wrapper--orders {
    padding: 20px 0; // Overwriting default styles

    @media (max-width: $maximum-width) {
      padding: 20px;
    }
  }
</style>
